import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { AbstractConfigurationService } from '../abstraction/abstract.configuration.service';
import { ICorrespondentMasterAccount } from '../../interfaces/configuration/correspondent-masteraccount.interface';
import { IRespondentFedNowSettings } from '../../interfaces/configuration/respondent-fednowsettings';
import { IRespondentTransactionalSettings } from '../../interfaces/configuration/respondent-transactionalsettings';
import { IInstitutionDualControl } from '../../interfaces/configuration/institution-dualcontrol.interface';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService extends AbstractConfigurationService {
  controller = `/manager/configuration`;
  urlPostCorrespondentMasterAccount = `${environment.apiUrl}${this.controller}/correspondentmasteraccount`;
  urlPostRespondentFedNowSettings = `${environment.apiUrl}${this.controller}/respondentfednowsettings`;
  urlPostTransactionalSettings = `${environment.apiUrl}${this.controller}/respondenttransactionalsettings`;
  urlPostInstitutionDualControl = `${environment.apiUrl}${this.controller}/institutiondualcontrol`;
  urlGetCorrespondentMasterAccount = `${environment.apiUrl}${this.controller}/correspondentmasteraccount`;
  urlGetRespondentFedNowSettings = `${environment.apiUrl}${this.controller}/respondentfednowsettings`;
  urlGetTransactionalSettings = `${environment.apiUrl}${this.controller}/respondenttransactionalsettings`;
  urlGetInstitutionDualControl = `${environment.apiUrl}${this.controller}/institutiondualcontrol`;

  constructor(private http: HttpClient) {
    super();
  }

  updateCorrespondentMasterAccount(
    masterAccount: ICorrespondentMasterAccount
  ): Promise<IWebMessage | undefined> {
    return this.http
      .post<IWebMessage>(`${this.urlPostCorrespondentMasterAccount}`, masterAccount)
      .toPromise();
  }
  updateRespondentFedNowSettings(
    fednowSettings: IRespondentFedNowSettings
  ): Promise<IWebMessage | undefined> {
    return this.http
      .post<IWebMessage>(`${this.urlPostRespondentFedNowSettings}`, fednowSettings)
      .toPromise();
  }
  updateRespondentTransactionalSettings(
    transactionalSettings: IRespondentTransactionalSettings
  ): Promise<IWebMessage | undefined> {
    return this.http
      .post<IWebMessage>(`${this.urlPostTransactionalSettings}`, transactionalSettings)
      .toPromise();
  }
  updateInstitutionDualControl(
    institutionDualControl: IInstitutionDualControl
  ): Promise<IWebMessage | undefined> {
    return this.http
      .post<IWebMessage>(`${this.urlPostInstitutionDualControl}`, institutionDualControl)
      .toPromise();
  }

  getCorrespondentMasterAccount(
    routingNumber: string
  ): Promise<ICorrespondentMasterAccount | undefined> {
    return this.http
      .get<ICorrespondentMasterAccount>(`${this.urlGetCorrespondentMasterAccount}/${routingNumber}`)
      .toPromise();
  }

  getRespondentFedNowSettings(
    routingNumber: string
  ): Promise<IRespondentFedNowSettings | undefined> {
    return this.http
      .get<IRespondentFedNowSettings>(`${this.urlGetRespondentFedNowSettings}/${routingNumber}`)
      .toPromise();
  }

  getRespondentTransactionalSettings(
    routingNumber: string
  ): Promise<IRespondentTransactionalSettings | undefined> {
    return this.http
      .get<IRespondentTransactionalSettings>(`${this.urlGetTransactionalSettings}/${routingNumber}`)
      .toPromise();
  }

  getInstitutionDualControl(routingNumber: string): Promise<IInstitutionDualControl | undefined> {
    return this.http
      .get<IInstitutionDualControl>(`${this.urlGetInstitutionDualControl}/${routingNumber}`)
      .toPromise();
  }
}
