import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MenuService } from '../app.menu.service';

export interface AppConfig {
  inputStyle: string;
  ripple: boolean;
  menuMode: string;
  menuTheme: string;
  topbarTheme: string;
  menuProfilePosition: string;
}

interface LayoutState {
  staticMenuMobileActive: boolean;
  overlayMenuActive: boolean;
  staticMenuDesktopInactive: boolean;
  configSidebarVisible: boolean;
  menuHoverActive: boolean;
  rightMenuActive: boolean;
  topbarMenuActive: boolean;
  menuProfileActive: boolean;
  revealMenuActive: boolean;
  anchored: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor(public menuService: MenuService) {}
  config: AppConfig = {
    ripple: true,
    inputStyle: 'outlined',
    menuMode: 'static',
    menuTheme: 'default',
    topbarTheme: 'default',
    menuProfilePosition: 'end',
  };

  state: LayoutState = {
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    rightMenuActive: false,
    topbarMenuActive: false,
    menuProfileActive: false,
    revealMenuActive: false,
    anchored: false,
  };

  private overlayOpen = new Subject<any>();

  private topbarMenuOpen = new Subject<any>();

  overlayOpen$ = this.overlayOpen.asObservable();

  topbarMenuOpen$ = this.topbarMenuOpen.asObservable();

  onMenuToggle() {
    if (this.isDesktop()) {
      this.state.staticMenuDesktopInactive = !this.state.staticMenuDesktopInactive;
    } else {
      this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;

      if (this.state.staticMenuMobileActive) {
        this.overlayOpen.next(null);
      }
    }

    if (!this.state.staticMenuDesktopInactive && this.isSlim()) this.config.menuMode = 'static';
    else this.config.menuMode = 'slim';
    if (this.isSlim() || this.isStatic()) {
      this.menuService.reset();
    }
  }

  onTopbarMenuToggle() {
    this.state.topbarMenuActive = !this.state.topbarMenuActive;
    if (this.state.topbarMenuActive) {
      this.topbarMenuOpen.next(null);
    }
  }

  onOverlaySubmenuOpen() {
    this.overlayOpen.next(null);
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isSlim() {
    return this.config.menuMode === 'slim';
  }

  isStatic() {
    return this.config.menuMode === 'static';
  }

  isMobile() {
    return !this.isDesktop();
  }
}
