import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AbstractConfigurationService } from '../abstraction/abstract.configuration.service';
import { IWebMessage } from '../../../common/interfaces/webmessage-interface';
import { ICorrespondentMasterAccount } from '../../interfaces/configuration/correspondent-masteraccount.interface';
import { IRespondentFedNowSettings } from '../../interfaces/configuration/respondent-fednowsettings';
import { IRespondentTransactionalSettings } from '../../interfaces/configuration/respondent-transactionalsettings';
import { IInstitutionDualControl } from '../../interfaces/configuration/institution-dualcontrol.interface';

@Injectable({
  providedIn: 'root',
})
export class MockConfigurationService extends AbstractConfigurationService {
  private getCorrespondentMasterAccountJson =
    'assets/mocks/configuration/getCorrespondentMasterAccount.json';
  private getRespondentFedNowSettingsJson =
    'assets/mocks/configuration/getRespondentFedNowSettings.json';
  private getRespondentTransactionalSettingsJson =
    'assets/mocks/configuration/getRespondentTransactionalSettings.json';
  private getInstitutionDualControlJson =
    'assets/mocks/configuration/getInstitutionDualControl.json';

  constructor(private http: HttpClient) {
    super();
  }

  updateCorrespondentMasterAccount(
    masterAccount: ICorrespondentMasterAccount
  ): Promise<IWebMessage | undefined> {
    throw new Error('Method not implemented.');
  }
  updateRespondentFedNowSettings(
    fednowSettings: IRespondentFedNowSettings
  ): Promise<IWebMessage | undefined> {
    throw new Error('Method not implemented.');
  }
  updateRespondentTransactionalSettings(
    transactionalSettings: IRespondentTransactionalSettings
  ): Promise<IWebMessage | undefined> {
    throw new Error('Method not implemented.');
  }
  updateInstitutionDualControl(
    institutionDualControl: IInstitutionDualControl
  ): Promise<IWebMessage | undefined> {
    throw new Error('Method not implemented.');
  }

  getCorrespondentMasterAccount(
    routingNumber: string
  ): Promise<ICorrespondentMasterAccount | undefined> {
    return lastValueFrom(this.http.get<any>(this.getCorrespondentMasterAccountJson));
  }
  getRespondentFedNowSettings(
    routingNumber: string
  ): Promise<IRespondentFedNowSettings | undefined> {
    return lastValueFrom(this.http.get<any>(this.getRespondentFedNowSettingsJson));
  }
  getRespondentTransactionalSettings(
    routingNumber: string
  ): Promise<IRespondentTransactionalSettings | undefined> {
    return lastValueFrom(this.http.get<any>(this.getRespondentTransactionalSettingsJson));
  }
  getInstitutionDualControl(routingNumber: string): Promise<IInstitutionDualControl | undefined> {
    return lastValueFrom(this.http.get<any>(this.getInstitutionDualControlJson));
  }
}
