import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { UserTypeService } from '../common/service/user-type.service';
import { ReturnListService } from '../fednow/service/http/return-list.service';
import { IMenu } from './interface/menu.interface';
import { IInstitutionConfiguration } from '../fednow/interfaces/institution/institution-configuration.interface';
import { AbstractInstitutionService } from '../fednow/service/abstraction/abstract.institution.service';
import { AbstractTransactionService } from '../fednow/service/abstraction/abstract.transaction.service';
import { AbstractConfigurationPendingChangesService } from '../fednow/service/abstraction/abstract.configuration-pending-changes.service';
import { AbstractConfigurationReviewStatusService } from '../fednow/service/abstraction/abstract.configuration-review-status.service';
import { BadgeService } from './service/badge.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: IMenu[] = [];
  returnListCount: number = 0;
  transactionCount: number = 0;
  configuration: IInstitutionConfiguration | undefined;

  constructor(
    private userTypeService: UserTypeService,
    private returnListService: ReturnListService,
    private transactionService: AbstractTransactionService,
    private institutionService: AbstractInstitutionService,
    private configurationPendingChangesService: AbstractConfigurationPendingChangesService,
    private configurationReviewStatusService: AbstractConfigurationReviewStatusService,
    private badgeService: BadgeService
  ) {}

  async loadCountInformation() {
    const [returnCount, transactionCount] = await Promise.all([
      this.returnListService.getPendingDecisionReturnCount(),
      this.transactionService.getCurrentCycleTransactionsCount(),
    ]);
    this.returnListCount = returnCount ?? 0;
    this.transactionCount = transactionCount ?? 0;
  }

  async loadConfiguration() {
    this.configuration = await this.institutionService.getConfiguration();
  }

  async ngOnInit() {
    await this.loadConfiguration();
    var isCorrespondent = this.userTypeService.isCorrespondent();
    var isStandAlone = this.userTypeService.isStandAlone();
    var beTreatedLikeRespondent = !isCorrespondent || (isCorrespondent && isStandAlone);
    this.loadMenuModal(!beTreatedLikeRespondent);
    this.loadBadgedes();
    this.badgeServiceSubcription();
  }

  private loadMenuModal(isCorrespondent: boolean) {
    this.model = [];
    this.model.push({
      label: 'FedNow',
      icon: 'pi pi-table',
    });
    this.model[0].label = 'FedNow';
    this.model[0].items = [];
    this.model[0].items.push({
      label: 'Account Activity',
      icon: 'pi pi-fw pi-table',
      routerLink: ['/fednow/account-activity'],
    });
    this.model[0].items.push({
      label: 'Transactions ',
      icon: 'pi pi-fw pi-arrow-right-arrow-left',
      visible: (!isCorrespondent && this.configuration?.isTransactionAllowed) ?? false,
      routerLink: ['/fednow/transaction'],
      badge: this.transactionCount,
    });
    this.model[0].items.push({
      label: 'Send Payment',
      icon: 'pi pi-dollar',
      visible: (!isCorrespondent && this.configuration?.isSendPaymentAllowed) ?? false,
      routerLink: ['/fednow/send-payment'],
    });
    this.model[0].items.push({
      label: 'Return List ',
      icon: 'pi pi-fw pi-server',
      visible: (!isCorrespondent && this.configuration?.isReturnListAllowed) ?? false,
      routerLink: ['/fednow/return-list'],
      badge: this.returnListCount,
    });
    this.model[0].items.push({
      label: 'Return Initiated ',
      icon: 'pi pi-fw pi-server',
      visible: (!isCorrespondent && this.configuration?.isReturnInitiatedAllowed) ?? false,
      routerLink: ['/fednow/return-initiated-list'],
    });
    this.model[0].items.push({
      label: 'RFP Received ',
      icon: 'pi pi-fw pi-server',
      visible: (!isCorrespondent && this.configuration?.isRFPReceiveAllowed) ?? false,
      routerLink: ['/fednow/rfp-list'],
    });
    this.model[0].items.push({
      label: 'Generate RFP ',
      icon: 'pi pi-fw pi-server',
      visible: (!isCorrespondent && this.configuration?.isRFPSendAllowed) ?? false,
      routerLink: ['/fednow/rfp-send'],
    });
    this.model[0].items.push({
      label: 'RFP Sent ',
      icon: 'pi pi-fw pi-server',
      visible: (!isCorrespondent && this.configuration?.isRFPSentListAllowed) ?? false,
      routerLink: ['/fednow/rfp-sent'],
    });
    this.model[0].items.push({
      label: 'Notifications',
      icon: 'pi pi-fw pi-bell',
      routerLink: ['/fednow/notification'],
    });
    this.model[0].items.push({
      label: 'Configuration',
      icon: 'pi pi-table',
      items: [
        {
          label: 'Settings',
          icon: 'pi pi-fw pi-book',
          visible: this.configuration?.isConfigurationAllowed ?? false,
          routerLink: ['/fednow/configuration'],
        },
        {
          label: 'Pending Reviews',
          icon: 'pi pi-fw pi-cog',
          visible: this.configuration?.isConfigurationPendingReviewAllowed ?? false,
          routerLink: ['/fednow/configuration/pending-changes'],
        },
        {
          label: 'Review Status',
          icon: 'pi pi-fw pi-cog',
          visible: this.configuration?.isConfigurationReviewStatusAllowed ?? false,
          routerLink: ['/fednow/configuration/pending-changes-review-status'],
        },
      ],
    });
  }

  private async loadBadgedes() {
    this.loadDualControlPendingChangesBadged();
    this.loadDualControlReviewStatusPendingBadged();
  }

  private async loadDualControlPendingChangesBadged() {
    var dualControlPendingChangesCount =
      (await this.configurationPendingChangesService.getCount()) ?? 0;
    if (dualControlPendingChangesCount > 0) {
      const configurationItem = this.model[0].items?.find(x => x.label === 'Configuration');
      const pendingReviewsItem = configurationItem?.items?.find(x => x.label === 'Pending Reviews');
      pendingReviewsItem!.badge = dualControlPendingChangesCount;
    }
  }

  private async loadDualControlReviewStatusPendingBadged() {
    var dualControlReviewStatusCount =
      (await this.configurationReviewStatusService.getCount()) ?? 0;
    if (dualControlReviewStatusCount > 0) {
      const configurationItem = this.model[0].items?.find(x => x.label === 'Configuration');
      const pendingReviewsItem = configurationItem?.items?.find(x => x.label === 'Review Status');
      pendingReviewsItem!.badge = dualControlReviewStatusCount;
    }
  }

  private badgeServiceSubcription() {
    this.badgeService.onEvent('updatePendingChanges').subscribe(x => {
      this.loadDualControlPendingChangesBadged();
    });

    this.badgeService.onEvent('updateReviewStatus').subscribe(x => {
      this.loadDualControlReviewStatusPendingBadged();
    });
  }
}
