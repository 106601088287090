import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BadgeService {
  private eventSubject = new Subject<{ key: string; data: any }>();

  constructor() {}

  fireEvent(key: string, data: any) {
    this.eventSubject.next({ key, data });
  }

  onEvent(key: string): Observable<any> {
    return this.eventSubject.asObservable().pipe(
      filter(event => event.key === key),
      map(event => event.data)
    );
  }

  unsubcribe() {
    this.eventSubject.unsubscribe();
  }
}
