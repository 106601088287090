import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { ICorrespondentMasterAccount } from '../../interfaces/configuration/correspondent-masteraccount.interface';
import { IRespondentFedNowSettings } from '../../interfaces/configuration/respondent-fednowsettings';
import { IRespondentTransactionalSettings } from '../../interfaces/configuration/respondent-transactionalsettings';
import { IInstitutionDualControl } from '../../interfaces/configuration/institution-dualcontrol.interface';

export abstract class AbstractConfigurationService {
  abstract updateCorrespondentMasterAccount(
    masterAccount: ICorrespondentMasterAccount
  ): Promise<IWebMessage | undefined>;
  abstract updateRespondentFedNowSettings(
    fednowSettings: IRespondentFedNowSettings
  ): Promise<IWebMessage | undefined>;
  abstract updateRespondentTransactionalSettings(
    transactionalSettings: IRespondentTransactionalSettings
  ): Promise<IWebMessage | undefined>;
  abstract updateInstitutionDualControl(
    institutionDualControl: IInstitutionDualControl
  ): Promise<IWebMessage | undefined>;

  abstract getCorrespondentMasterAccount(
    routingNumber: string
  ): Promise<ICorrespondentMasterAccount | undefined>;
  abstract getRespondentFedNowSettings(
    routingNumber: string
  ): Promise<IRespondentFedNowSettings | undefined>;
  abstract getRespondentTransactionalSettings(
    routingNumber: string
  ): Promise<IRespondentTransactionalSettings | undefined>;
  abstract getInstitutionDualControl(
    routingNumber: string
  ): Promise<IInstitutionDualControl | undefined>;
}
